<script lang="ts">
import { PropType } from 'vue';

interface ButtonInterface {
    name: string,
    link: string
}
export default {
    name: "CardInfo",
    props:{
        background:{type: String, default: 'white-bg'},
        height: {type: Number, default: 40}
    },
}

</script>

<template>

    <div class="shrink-0 w-[35rem] text-[1.5rem] rounded-3xl sm:w-[35rem] mt-[2rem]" 
        :class="`${background}`"
        :style="`height: ${height}rem`">
        <slot/>
    </div>


</template>
