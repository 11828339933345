import { IFormInstance, ModelForm } from "../services/Form";

export interface InvestidorDetail {
    nome: string;
    saldo_em_carteira: number;
    conta_fitbank_status: boolean;
    export_investimentos_url: string;
    download_todos_arquivos_link: string;
    email: string;
}

export interface IDadosPessoais extends IFormInstance{
    id: number;
    nome: string;
    cpf: string;
    rg: string;
    genero: string;
    data_de_nascimento: string;
    estado_civil: string;
    nacionalidade: string;
    nome_da_mae: string;
    renda_mensal: string;
    patrimonio: string;
    profissao: string;
    telefone_celular: string;
    sou_uma_pessoa_politicamente_exposta: boolean;
    onde_conheceu: string;
    aprovada: boolean | undefined;
}

export class DadosPessoaisForm extends ModelForm {
    instance!: IDadosPessoais;
    dates = ['data_de_nascimento']
    numbers = ['renda_mensal', 'patrimonio'];
}


export interface IDadosEndereco extends IFormInstance {
    id: number;
    cep: string;
    logradouro: string;
    numero: string;
    nao_possui_numero: boolean;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: number;
    pais: string;
}

export class DadosEnderecoForm extends ModelForm {
    instance!: IDadosEndereco;
}

export interface IDadosDocumentos extends IFormInstance {
    id: number;
    documento_tipo: string;
    documento_frente: string;
    documento_verso: string;
    comprovante_selfie: string;
    documento_data_expedicao: string;
    documento_data_validade: string;
    documento_orgao_expedidor: string;
    documento_uf_orgao_expedidor: number;
    comprovante_residencia_data_emissao: string;
    comprovante_residencia: string;
    comprovante_declaracao_residencia: string;
    nao_possuo_comprovante_de_residencia_no_nome: boolean;
    aprovada?: boolean;
}

export class DadosDocumentosForm extends ModelForm {
    instance!: IDadosDocumentos;
    dates = ['documento_data_expedicao', 'documento_data_validade', 'comprovante_residencia_data_emissao'];
}

export interface IDadosAcesso extends IFormInstance {
    new_password1: string;
    new_password2: string;
    uuid?: string;
}

export class DadosAcessoForm extends ModelForm {
    instance!: IDadosAcesso;
}
